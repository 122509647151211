import React from "react"
import { PageProps } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Work from "../../components/pages/work"

const MetaWorkPage: React.FC<PageProps> = pageProps => {
  return (
    <Layout pageProps={pageProps}>
      <SEO title="Habitats | Work" />
      <Work />
    </Layout>
  )
}

export default MetaWorkPage
