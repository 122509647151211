import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import metaWorkBanner from "../../../images/metaWorkColor.gif"
import shopTowerWork from "../../../images/twinRendering1.png"

const styles = require("./work.module.scss");

type WorkProps = {}

const Work: React.FC<WorkProps> = ({}) => {
  return (
    <>
      <Container className={`${styles.contentContainer} ${styles.introContainer}`}>
        <Row>
          <Col className="col-12 col-md-9">
            <p className={styles.introText}>
              <span className={styles.highlightedText}>Habitats</span> is more than an NFT project. SHoP is looking to make its mark on the metaverse. Already, SHoP is developing a large scale masterplan in Decentraland in partnership with virtual real estate owners and is looking to find ways to develop these spaces responsibly to give users an experience like they have never had.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={styles.contentContainer}>
        <Row>
          <Col className={`${styles.dashContainer} col-12 col-md-3`}>
            <div className={styles.dashSectionBreak} />
          </Col>
        </Row>
        <Row className={styles.moduleRow}>
          <Col className={`${styles.videoContainer} col-12 col-md-10`}>
            <Image className={`${styles.placeHolderImage}`} src={metaWorkBanner} />
            <p className={styles.descriptionTextSmall}>Drawing of a virtual world with Habitats, by SHoP Architects</p>
          </Col>
        </Row>
        <Row className={`${styles.moduleRow} ${styles.reverseRowOnMobile}`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-5 col-lg-4`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              SHoP is looking to have events in both virtual and physical worlds, host a habitats design competition, and find new ways to continue to engage and collaborate within design and web3 communities.
            </p>
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-7`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={shopTowerWork} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Work
